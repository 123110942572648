import Body from '../components/Body';
import Spacer from '../components/Spacer';
import TextBlock from '../components/TextBlock';
import TextBlockTwo from '../components/TextBlockTwo';
import '../App.css';
import BarMenu from '../components/BarMenu';
import Banner from '../components/Banner';
import NewFooter from '../components/NewFooter';
import FeaturesBlock from '../components/FeaturesBlock';

const title = "Come nasce Essence?";
const t1 = "Essence nasce ufficialmente nel 2023 come idea di Andrea Tempestini, un ragazzo ventitreenne di Gallarate con una grande visione.";
const t2 = "Il suo sogno era quello di creare un'app essenziale che integrasse diverse funzionalità per semplificare la vita degli utenti senza la necessità di dover ricorrere ad app terze.";
const t3 = "Essence è nata dalla ferma volontà di creare un'app semplice, bella ed intuitiva che potesse aiutare gli utenti a migliorarsi attraverso una gestione consapevole della propria routine quotidiana.";

const bodyTitle = "Sogna. Crea. Cambia.";
const bodySubtitle = "Essence";
const bodyDescription = "Un'esperienza digitale consapevole per guidare l'utente verso la riscoperta di sé.";
const bodyButtonTitle = "Scarica ora";

const ftitle = "Un nuovo modo di vivere";
const f1 = "Essence permette ad ogni utente di monitorare i propri risultati scolastici e universitari, tenere traccia delle proprie spese, organizzare al meglio l’allenamento in palestra, prendersi cura della propria famiglia, sorprendere il proprio partner ed occuparsi della propria salute fisica e mentale.";
const f2 = "Tutto questo è possibile grazie alla presenza di diverse sezioni: Università, Sport, Finanze, Salute, Famiglia, Amore e Scuola.";
const f3 = "Nel dettaglio, all'interno di ognuna di queste sezioni l'utente potrà:";
const f4 = "Università, tenere sotto controllo la propria carriera universitaria, gli esami superati, la media conseguita e la proiezione del voto di laurea. Visualizzare grafici personalizzati per controllare l'andamento dei voti ricevuti e il numero di esami per voto. Monitorare i crediti acquisiti e il progresso della propria carriera universitaria. Segnare le tasse universitarie.";
const f5 = "Sport, inserire le proprie schede di allenamento con i relativi esercizi per allenarsi più agevolmente in palestra. Accedere a funzionalità di Timer e Cronometro. Controllare quanta acqua ha bevuto giorno per giorno. Impostare obiettivi di peso corporeo e visualizzare i propri progressi attraverso grafici personalizzati e funzionalità di diario. Verificare il proprio indice di massa corporeo.";
const f6 = "Finanze, controllare le proprie spese giornaliere, il proprio flusso di cassa, la spesa mensile, la differenza rispetto al mese precedente, la propria media giornaliera di spesa. Potrà inoltre categorizzare ogni movimento inserito come entrata od uscita, oltre ad assegnare quest'ultime a sottocategorie personalizzate (Ristorante, Shopping, Trasporto...) e personali (dall'utente create). Potrà inoltre visualizzare grafici settimanali, mensili e annuali attraverso una schermata di Statistiche dettagliate.";
const f7 = "Salute, accedere ad una funzione di diario per tenere sotto controllo il proprio benessere emotivo. Avrà la possibilità di registrare ogni giorno il proprio umore, allegando una nota personale. Sarà anche in grado di controllare la propria situazione sanitaria, inserendo le medicine che deve assumere giornalmente.";
const f8 = "Famiglia, accedere a funzionalità di preventivo di spesa per conoscere in anticipo quanto spenderà alla cassa del supermercato. Potrà inoltre segnare i prossimi eventi attesi come la visita dentistica e le prossime bollette da pagare.";
const f9 = "Amore, sorprendere il proprio partner ricordandosi ogni anniversario. Oltre a poter includere i propri ricordi più speciali e segnarsi ogni cosa da non dimenticare in un diario apposito.";
const f10 = "Scuola, monitorare il proprio andamento scolastico inserendo le materie di studio e i relativi voti. Visualizzare la media generale per materia e complessiva. Segnare l'orario scolastico e visualizzarlo con estrema comodità.";
const f11 = "Agenda, tenere sotto controllo tutti i tuoi appuntamenti. Visualizzare schermate riassuntive degli eventi giornalieri oltre a riepiloghi settimanali. Aggiungere appunti e note con tanto di foto e descrizione. Oltre a poter fissare le note che ritieni più importanti.";

function Home() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <Body title={bodyTitle} subtitle={bodySubtitle} description={bodyDescription} buttonTitle={bodyButtonTitle}></Body>
      <Spacer></Spacer>
      <TextBlock title={title} t1={t1} t2={t2} t3={t3}></TextBlock>
      <Spacer></Spacer>
      <FeaturesBlock title={ftitle} t1={f1} t2={f2} t3={f3} t4={f4} t5={f5} t6={f6} t7={f7} t8={f8} t9={f9} t10={f10} t11={f11}></FeaturesBlock>
      <Spacer></Spacer>
      <TextBlockTwo title="Scopri un nuovo modo di vivere" description="Provala subito."></TextBlockTwo>
      <Spacer></Spacer>
      <NewFooter></NewFooter>
    </div>
  );
}

export default Home;
