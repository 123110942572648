import "./TextBlock.css";
import { ReactComponent as Illustration } from "../svg/onb7.svg";

function TextBlock(props) {


  return (
    <div className="wrapper">
    <div className="textblock">

      <div className="row-illustration">
             

      <p className="textblock__text">
      <p className="textblock__title">{props.title}</p>
        {props.t1}
        <br />
        <br />
        {props.t2}
        <br />
        <br />
        {props.t3}
      </p>
        <div className="illustration">
          <Illustration></Illustration>
        </div>
      </div>
      </div>
    </div>
  );
}

export default TextBlock;
