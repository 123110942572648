import Spacer from "../components/Spacer";
import React from "react";
import Banner from "../components/Banner";
import BarMenu from "../components/BarMenu";
import NewFooter from '../components/NewFooter';
// import DeleteBody from "../components/DeleteBody";
import TextBlockDelete from "../components/TextBlockDelete";

function Delete() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <TextBlockDelete />
      <Spacer></Spacer>
      <NewFooter></NewFooter>

    </div>
  );
}

export default Delete;
