import "./TextBlockTwo.css";

function TextBlockTwo(props) {
  return (
    <div className="textblocktwo">
      <p className="textblocktwo__title">{props.title}</p>
      <p className="textblocktwo__text">{props.description}</p>
    </div>
  );
}

export default TextBlockTwo;
