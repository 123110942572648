import './Banner.css';

function Banner() {
  return (
    <div className='banner'>
        <p>Supporta lo sviluppo di Essence, dona subito!</p>
    </div>
  );
}
// in questo modo esporto la funzione come esportazione principale/predefinita

export default Banner; 