import "./Body.css";
import { ReactComponent as Illustration } from "../svg/onb3.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";


function Body(props) {
  const { pathname } = useLocation();

  return (
    <div className="home__wrapper">
    <div className="home__body">
      <div className="home__row">
        <div className="home__body-column-left">
          <p className="home__body-column__word">
            {props.title}
          </p>
          <div className="home__body-column__row">
            <div className="home__body-column__row-line"></div>
            <p className="home__body-column__row-line-text">{props.subtitle}</p>
          </div>
          <p className="home__body-column__description">
            {props.description}
          </p>
          <Link
          to="/download" style={{
            textDecoration: "none",
            color: pathname === "/" ? "black" : "#adafb2",
          }}><div className="home__button">{props.buttonTitle}</div></Link>
        </div>
        <div className="home__body-column-right">
          <Illustration></Illustration>
        </div>
      </div>
    
    </div>
    </div>
  );
}



export default Body;
