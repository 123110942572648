import Spacer from "../components/Spacer";
import React from "react";
import Banner from "../components/Banner";
import BarMenu from "../components/BarMenu";
import TextBlockTwo from "../components/TextBlockTwo";
import FAQ from "../components/FAQ";
import WhyDonate from "../components/WhyDonate";
import SecondBody from "../components/SecondBody";
import NewFooter from '../components/NewFooter';

const title = "Perché donare?";
const t1 =
  "Nella breve ma intensa passeggiata della vita, ci troviamo costantemente a dover scegliere il sentiero da percorrere. A volte, le scelte che facciamo possono sembrare opposte e incompatibili, come se fossimo chiamati a un difficile gioco di equilibrio. Eppure, sono proprio queste scelte che danno forma al nostro destino, che ci permettono di crescere e di esplorare nuovi orizzonti.";
const t2 =
  "Essence è il frutto di una visione ambiziosa e di un lavoro instancabile: semplificare la vita delle persone. Nonostante gli sforzi che abbiamo profuso, il progetto attualmente versa in perdita. Sostenere Essence rappresenta un'occasione unica per sostenere un sogno capace di diventare realtà. Donando ci aiuteresti a mantenere l’applicazione disponibile sugli store e a coprire i costi sostenuti per il suo sviluppo, oltre a permetterci di acquistare nuova attrezzatura per una più efficiente manutenzione. Inoltre, qualora lo ritenessimo opportuno, potremmo decidere di valutare l'apertura di una Partita IVA e di introdurre abbonamenti in-app opzionali, di modo da garantire la sostenibilità della nostra applicazione nel lungo termine. Tuttavia, consapevoli dell'imprevedibilità del mercato, se tale percorso non risultasse sostenibile, ciò non vanificherebbe i nostri sforzi. Infatti, essi continueranno a essere indirizzati a mantenere Essence disponibile al pubblico, finché questi ce lo consentiranno.";
const t3 =
  "Ad Essence, crediamo che piccole azioni possano portare grandi cambiamenti. Ecco perché ti chiediamo di unirti a noi in questo progetto, di essere parte del nostro sogno e di contribuire a rendere il mondo un posto più consapevole. Grazie, qualunque cosa tu decida di fare.";

const thankyouTitle = "Grazie";
const thankyouDescription =
  "A chiunque vorrà aiutarci nella realizzazione di questo sogno.";

const titleFAQ = "Domande frequenti";

const q1 = "D: Qual è l'importo minimo che posso donare a Essence?";
const a1 =
  "Accettiamo donazioni di qualsiasi entità e siamo grati per ogni contributo che ci aiuta a sostenere il nostro progetto. Ogni donazione, anche la più piccola, ci aiuta a mantenere l'applicazione disponibile a tutti gli utenti, oltre a contribuire al suo incessante sviluppo. Tuttavia, la piattaforma che utilizziamo per raccogliere le donazioni potrebbe richiedere un importo minimo, che puoi consultare recandoti su di essa. Ti invitiamo a donare ciò che ti è possibile e che ritieni giusto per te.";

const q2 =
  "D: Se raggiungerete l'obiettivo di donazioni previsto aprirete Partita IVA?";
const a2 =
  "La nostra visione è quella di offrire una soluzione innovativa e di alta qualità per semplificare la vita delle persone, ma è la risposta del pubblico a determinare la fattibilità di questo obiettivo. Anche se le donazioni sono un elemento importante per il nostro progetto, non sarebbe prudente avventurarsi nell'apertura di una Partita IVA se non si verificassero alcuni fattori che riteniamo cruciali, come un numero sufficiente di utenti attivi. In tali circostanze, investiremo il ricavato nella manutenzione di Essence e nel suo miglioramento, acquisendo attrezzature avanzate per lo sviluppo di nuove funzionalità e garantendo che il nostro prodotto rimanga disponibile al pubblico il più a lungo possibile. La nostra priorità è sempre stata quella di fornire un servizio alla comunità, e continuerà ad esserlo anche in futuro, indipendentemente dalle circostanze. Quando si effettua una donazione si accetta l'eventualità che l'apertura della Partita IVA possa non avvenire, in quanto ci riserviamo il diritto di prendere decisioni discrezionali in merito, basate sulle nostre valutazioni, al fine di massimizzare l'impatto e l'utilizzo dei fondi donati.";


  const bodySubtitle = "Insieme";
  const bodyDescription = "Per rendere il mondo un posto consapevole.";
  const bodyButtonTitle = "Contribuisci";

function Donate() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <SecondBody subtitle={bodySubtitle} description={bodyDescription} buttonTitle={bodyButtonTitle}></SecondBody>
      <Spacer></Spacer>
      <WhyDonate
        title={title}
        t1={t1}
        t2={t2}
        t3={t3}
      ></WhyDonate>
      <Spacer></Spacer>
      <FAQ
        title={titleFAQ}
        q1={q1}
        a1={a1}
        q2={q2}
        a2={a2}
      ></FAQ>
      <Spacer></Spacer>
      <TextBlockTwo
        title={thankyouTitle}
        description={thankyouDescription}
      ></TextBlockTwo>{" "}
      <Spacer></Spacer>
      <NewFooter></NewFooter>

    </div>
  );
}

export default Donate;
