import "./DownloadBlock.css";
import GooglePlay from "../images/google-play.png";
import AppleStore from "../images/app-store.png";
import { ReactComponent as Illustration } from "../svg/auth6.svg";

function DownloadBlock(props) {
  return (
    <div className="downloadblock">
       <p className="downloadblock__text">Inizia ora</p>
      <div className="downloadblock__image">
        <Illustration></Illustration>
      </div>
      <div className="row">
      
      
        <a class="google-div" href="https://play.google.com/store/apps/details?id=it.andreatempestini.essence" target="_blank">
          <img className="playstore" src={GooglePlay} alt="Play Store" />
          </a>
        

        <a class="apple-div" href="https://apps.apple.com/it/app/essence/id1619137442" target="_blank">
        <img className="applestore" src={AppleStore} alt="Apple Store" />
        </a>

       
      </div>
    </div>
  );
}

export default DownloadBlock;
