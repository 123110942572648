import "./DownloadBody.css";
import { ReactComponent as Illustration } from "../svg/auth6.svg";


function DownloadBody(props) {
  return (
    <div className="download__wrapper">
      <div className="download__body">
        <div className="download__row">
          <div className="download__body-column-left">
            <p className="download__body-column__word">
              Scarica <br /> Essence
            </p>
            <div className="download__body-column__row">
              <div className="download__body-column__row-line"></div>
              <p className="download__body-column__row-line-text">{props.subtitle}</p>
            </div>
            <p className="download__body-column__description">{props.description}</p>
            
          </div>
          <div className="download__body-column-right">
            <Illustration></Illustration>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadBody;
