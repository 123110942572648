import Spacer from "../components/Spacer";
import React from "react";
import Banner from "../components/Banner";
import BarMenu from "../components/BarMenu";
import TextBlockTwo from "../components/TextBlockTwo";
import DownloadBody from "../components/DownloadBody";
import DownloadBlock from "../components/DownloadBlock";
import NewFooter from '../components/NewFooter';


const thankyouTitle = "Sviluppata con amore";
const thankyouDescription = "da Andrea Tempestini";

const bodySubtitle = "Adesso";
const bodyDescription = "Un nuovo viaggio ti attende appena sarai pronto a partire.";
const bodyButtonTitle = "Contribuisci";

function Download() {
  return (
    <div>
      <Banner></Banner>
      <BarMenu></BarMenu>
      <Spacer></Spacer>
      <DownloadBody
        subtitle={bodySubtitle}
        description={bodyDescription}
        buttonTitle={bodyButtonTitle}
      ></DownloadBody>
      <Spacer></Spacer>
      <DownloadBlock></DownloadBlock>
      <Spacer></Spacer>
      <TextBlockTwo
        title={thankyouTitle}
        description={thankyouDescription}
      ></TextBlockTwo>{" "}
      <Spacer></Spacer>
      <NewFooter></NewFooter>

    </div>
  );
}

export default Download;
