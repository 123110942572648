import "./SecondBody.css";
import { ReactComponent as Illustration } from "../svg/love3.svg";


function SecondBody(props) {
  return (
    <div className="donate__wrapper">
    <div className="donate__body">
      <div className="donate__row">
        <div className="donate__body-column-left">
          <p className="donate__body-column__word">
            Sostieni <br /> Essence
          </p>
          <div className="donate__body-column__row">
            <div className="donate__body-column__row-line"></div>
            <p className="donate__body-column__row-line-text">{props.subtitle}</p>
          </div>
          <p className="donate__body-column__description">
            {props.description}
          </p>
          <div className="donate__button" onClick={onClick}>{props.buttonTitle}</div>
        </div>
        <div className="donate__body-column-right">
            
          <Illustration></Illustration>
          
        </div>
      </div>
    
    </div>
    </div>
  );
}

const onClick = () => {
    const url = 'https://www.gofundme.com/f/essence-unapp-per-riscoprire-te-stesso';
    window.open(url, '_blank');
};

export default SecondBody;
