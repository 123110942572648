import "./NewFooter.css";

function NewFooter() {
  return (
    <div className="wrapper">
        <div className="between">
    <div className="newfooter">
      <a href="https://www.iubenda.com/privacy-policy/83462831/full-legal" target="_blank" className="newfooter__text">Informativa sulla privacy</a>
      <a href="https://www.iubenda.com/termini-e-condizioni/83462831" target="_blank" className="newfooter__text">Termini e condizioni</a>
      <a href="/delete" target="_blank" className="newfooter__text">Elimina account</a>
      <a href="https://www.instagram.com/andreatempestini" target="_blank" className="newfooter__text">© 2024 Andrea Tempestini</a>
    </div></div></div>
  );
}

export default NewFooter;
