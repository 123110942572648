import "./BarMenu.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function BarMenu() {
  const { pathname } = useLocation();

  return (
    <div className="bar-menu">
      <Link to="/" style={{textDecoration: "none", color: "black", fontFamily: 'Rubik', fontWeight: 600 }}>
        <div className="bar-menu__title">Essence</div>
      </Link>
      <div className="bar-menu__options">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: pathname === "/" ? "black" : "#adafb2",
          }}
        >
          <div className="bar-menu__home">Home</div>
        </Link>
        <Link
          to="/donate"
          style={{
            textDecoration: "none",
            color:pathname === "/donate" ? "black" : "#adafb2",
          }}
        >
          <div className="bar-menu__support">Sostieni</div>
        </Link>
        <Link
          to="/download"
          style={{
            textDecoration: "none",
            color: pathname === "/download" ? "black" : "#adafb2",
          }}
        >
          <div className="bar-menu__scarica">Scarica</div>
        </Link>
      </div>
      <Link
          to="/download"
          style={{
            textDecoration: "none",
            color: pathname === "/download" ? "black" : "#adafb2",
          }}
        >  <div className="bar-menu__download">
        Ottieni
      </div>
      </Link>
    </div>
  );
}



export default BarMenu;
