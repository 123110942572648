import "./FeaturesBlock.css";
import Phone from "../images/g1.png";

function FeaturesBlock(props) {


  return (
    <div className="wrapper">
    <div className="textblock">

      <div className="row-illustration">
             

      <p className="textblock__text">
      <p className="textblock__title">{props.title}</p>
        {props.t1}
        <br />
        <br />
        {props.t2}
        <br />
        <br />
        {props.t3}
        <br />
        <br />
        {props.t4}
        <br />
        <br />
        {props.t5}
        <br />
        <br />
        {props.t6}
        <br />
        <br />
        {props.t7}
        <br />
        <br />
        {props.t8}
        <br />
        <br />
        {props.t9}
        <br />
        <br />
        {props.t10}
        <br />
        <br />
        {props.t11}
      </p>
        <img className="illustration__phone" src={Phone} alt="Essence">
            
    
        </img>
        
      </div>
      </div>
    </div>
  );
}

export default FeaturesBlock;
